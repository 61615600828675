import * as React from 'react'
import Layout from '../components/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import styled from "styled-components";

const WorksPage = ({ data }) => {
  return (
    <Layout pageTitle="works" desc="View selected works by Michael Raymond Lund">
      {
        data.allImageDataJson.edges.map((edge, i) => (
          <Painting  key={i}>
            <GatsbyImage image={edge.node.src.childImageSharp.gatsbyImageData} alt="" />
            <p>{edge.node.name}</p>
            <p>{edge.node.details}</p>
          </Painting>
        ))
      }
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  allImageDataJson {
    edges {
      node {
        name
        details
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 750)
          }
        }
      }
    }
  }
}`

export default WorksPage

const Painting = styled.article`
    padding: 2rem;
    font-style: italic;
`